@import "../../utils";

$rightPanelBorderColor: #DFE0E6;
$rightPanelBgColor: #f7f7f7;

.rightDrawer {
    visibility: hidden;
    position: fixed;
    transition: 200ms all ease-in-out;
    transform: translateX(274px);
    will-change: transform;
    background-color: #fff;
    z-index: 99;
    width: 274px;
    right: 0;
    height: 100%;

    &:focus {
        outline: 0;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        height: 57px;
        padding: 0 16px;
        box-sizing: border-box;
        border-bottom: 1px solid $rightPanelBorderColor;
        background-color: #fff;
        box-shadow: 0 10px 80px rgb(0 0 0 / 5%);

        span {
            font-size: 12px;
            font-weight: 500;
        }
    }

    &__content {
        .pages {
            .pageList {
                max-height: calc(100vh - 150px);
                overflow-y: auto;
                max-width: 100%;
                padding: 4px 12px 0;

                @include scrollbar;

                &:focus {
                    outline: 0;
                }

                .pageItem {
                    width: 100%;
                    background-color: #fff;
                    border: 1px solid $rightPanelBorderColor;
                    border-radius: 4px;
                    margin: 20px 0;
                    user-select: none;
                    box-sizing: border-box;
                    height: 150px;

                    .thumbnail {
                        height: 107px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }

                    .details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: nowrap;
                        border-top: 1px solid $rightPanelBorderColor;
                        box-sizing: content-box;

                        > span {
                            font-size: 12px;
                            font-weight: 400;
                            cursor: pointer;
                            padding: 10px 16px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 100%;
                            box-sizing: border-box;
                            color: var(--color-default-text);
                            white-space: nowrap;
                            overflow: hidden;

                            &:not(.--hasPageName) {
                                color: #83889E;
                            }
                        }

                        > input {
                            outline: none;
                            border: none;
                            font-size: 12px;
                            padding: 10px 16px;
                            height: 40px;
                            box-sizing: border-box;
                            color: var(--color-default-text);

                            &::placeholder {
                                color: #83889E;
                            }
                        }

                        button {
                            background-color: transparent;
                            border: none;
                            outline: none;
                            width: 20px;
                            height: 20px;
                            margin-right: 16px;
                        }
                    }

                    &.__active {
                        border-color: var(--color-apeiros-purple-dark);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &.__isDropdownVisible {
                    .pageItem:last-child {
                        .dropdownMenu {
                            top: -100px;
                        }
                    }
                }
            }

            .addPageBtn {
                border: 0;
                outline: 0;
                cursor: pointer;
                background-color: #F3EBFF;
                height: 36px;
                margin: 20px auto 0;
                padding: 16px 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                width: 137px;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0.14px;
                white-space: nowrap;

                span {
                    margin-left: 8px;
                    color: #262626;
                }

                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }
        }
    }

    &__closeBtn {
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        width: 14px;
        height: 14px;
    }

    &.__visible {
        visibility: visible;
        transform: translateX(0);
    }
}

#pagesDeleteBtn {
    background-color: #FF8A80;
}
