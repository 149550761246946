$borderColor: #BFBFBF;
$bgColor: #F5F5F5;
$dividerColor: #DFE0E6;

.topBar__left {
    position: absolute;
    left: 0;
    top: 12px;
    z-index: 99;
    display: inline-flex;
    align-items: center;
    margin-left: 12px;
    width: auto;

    /* 100% - a - b - c - d */

    /* a: margin-left of top bar left */

    /* b, c: width of top bar right */

    /* d: for adding margin between two wrapper */
    max-width: calc(100% - 30px - 238px - 36px - 85px);
    background-color: #f8f9fa80;
    backdrop-filter: blur(12px);
    border-radius: 4px;
    padding: 2px 7px;

    .logo {
        width: 42px;
        height: 42px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.--externalProject {
            cursor: initial;
        }
    }

    .nameWrapper{
        display: flex;
        flex-direction: row;
        width: calc(100% - 42px);
        padding-left: 20px;
        box-sizing: border-box;
        flex: 1;

        .iconEdit {
            visibility: hidden;
            opacity: 0;
            margin-left: 8px;
            width: 15px;
            position: absolute;
            right: -23px;
            top: 50%;
            transform: translateY(-50%);
            transition-delay: 500ms;
        }

        &:hover{
            .iconEdit{
                transition-delay: 0ms;
                visibility: visible;
                opacity: 1;
                cursor: pointer;
            }
        }

        &.--not-allowed {
            height: 48px;
            flex-direction: column;
            justify-content: center;
        }
    }

    .boardName {
        width: auto;
        max-width: 100%;
        height: 48px;
        background: transparent;
        border: none;
        padding: 0;
        line-height: 3;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: var(--font-family-default);
        color: var(--base-blue-grey-10, #3C3E49);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        align-self: center;

        &.show {
            display: inline-block;
            margin-bottom: 20px;
        }

        &.hide {
            display: inline-block;
        }

        &:focus-visible {
            outline: none;
            border: none;
        }

        &__wrapper {
            width: 100%;
            box-sizing: border-box;

            &.--editing {
                width: 100%;
            }
        }
    }

    .boardNameText {
        max-width: 100%;
        background: transparent;
        border: none;
        padding: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: var(--font-family-default);
        color: var(--base-blue-grey-10, #3C3E49);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        align-self: center;
        user-select: none;
        height: 100%;
        line-height: 48px;
    }

    .search {
        width: 48px;
        height: 48px;
        background: #F5F5F5;
        border: 0.5px solid #BFBFBF;
        box-shadow: 0 5px 9px rgb(212 212 212 / 50%);
        border-radius: 5px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        em {
            font-size: 22px;
            color: #292D32;
            position: relative;
            top: 2px;
        }
    }
    
    .request-access{
        &-pending{
            font-size: 14px;
            line-height: 28px;
            color: #83889E;
            margin-left: 8px;
        }
        
        &-button{
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            gap: 5px;
            
            img{
                width: 15px;
                margin-left: 11px;
            }
            
            div{
                white-space: nowrap;
                opacity: 0;
                visibility: hidden;
                overflow:hidden;
                transition: width 0.2s ease;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: rgba(60 62 73 / 100%);
            }
            
            &-container{
                border: 1px solid rgba(179 136 255 / 30%);
                position: relative;
                width: max-content;
                height: 36px;
                display: flex;
                align-items: center;
                border-radius: 8px;
                background: #fff;
                box-sizing: border-box;
                transition: all 0.2s ease;
                margin-left: 10px;

                &.active{
                    .request-access{
                        &-upgrade-button-wrapper{
                            background: #fff;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: 8px;
                            transition: all 0.2s ease;
                        }
                        
                        &-button{
                            width: 138px;
                            transition: width 0.2s ease;
                            
                            &.comment{
                                width: 172px;
                            }
                            
                            div{
                                visibility: visible;
                                opacity: 1;
                                transition: opacity 0.2s ease-in;
                            }
                        }
                    }

                    img{
                        margin-left: 0;
                    }
                }
            }
        }

        &-upgrade{
            &-button{
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #262626;
                background: #00E676;
                cursor: pointer;
                border-radius: 8px;
                padding: 6px 16px;
                box-sizing: border-box;
                
                &-wrapper{
                    transition: all 0.2s ease;
                    position: absolute;
                    top: 36px;
                    left: 0;
                    display: none;
                    width: 200px;
                    height: 84px;
                    border: 1px solid rgba(245 245 245 / 100%);
                    border-radius: 8px;
                    box-shadow: 0 2px 4px 0 rgba(0 0 0 / 15%);
                    box-sizing: border-box;
                }
            }
            
            &-title{
                font-size: 10px;
                line-height: 16px;
                color: #83889E;
            }
        }
    }

    .flowchartButtonWrapper {
        position: absolute;
        right: calc(-48px - 20px);
    }
}

.topBar__right {
    position: absolute;
    right: 0;
    top: 12px;
    z-index: 11;
    display: inline-flex;
    transition: 200ms all ease-in-out;

    &.--searchBarExpanded {
        z-index: 99;
    }
    
    &.--commentDrawerExpanded{
        right: 283px;
        transition: 200ms all ease-in-out;
    }
    
    &.--rightPanelExpanded{
        right: 274px;
        transition: 200ms all ease-in-out;
    }

    .topBarHeaderSearch{
        width: auto;
        position: relative;
        margin-right: 20px;
    }

    .moreOptions {
        width: 36px;
        height: 36px;
        min-width: 36px;
        border: 1px solid rgb(179 136 255 / 30%);
        box-sizing: border-box;
        background:  #FFF;
        border-radius: 8px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-delay: 100ms;

        &:hover {
            transition-delay: 0ms;
            background: var(--Base-Purple-1, #F3EBFF);
        }

        .more {
            em {
                font-size: 15px;
                position: relative;
                top: 2px;
                color: var(--color-default-text);
            }
        }

        .moreOptionsBlock {
            position: absolute;
            right: 0;
            top: 38px;
            visibility: hidden;
            opacity: 0;
            transition: all .2s ease-in-out;

            .moreOptionsInner {
                position: relative;
                width: 192px;
                height: auto;
                background: #fff;
                border-radius: 8px;
                border: 1px solid  #ECEDF0;
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

                .import, .export, .boardSetting, .userFlowItem, .activityLog, .importUserFlow, .headerMoreButtonItem, .board-item-menu {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    height: 40px;
                    padding: 0 6px;

                    span {
                        font-family: var(--font-family-default);
                        color: var(--color-default-text);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.12px;
                        margin-left: 10px;
                    }

                    em {
                        margin-left: 16px;
                        font-size: 15px;
                        color: var(--color-default-text);
                    }

                    svg, img {
                        margin-left: 16px;
                    }

                    &:not(.disabled):hover {
                        background: var(--Base-Purple-1, #F3EBFF);

                        .importOptionsBlock, .exportOptionsBlock {
                            right: -155px;
                            visibility: visible;
                            opacity: 1;
                            transition: all .2s ease-in-out;
                        }
                    }

                    &.disabled {
                        cursor: not-allowed;
                        user-select: none;

                        em, span {
                            color: rgb(107 106 106)!important;
                        }
                    }

                    &:last-child {
                        border: none;
                    }

                    .importOptionsBlock {
                        position: absolute;
                        left: -143px;
                        top: 0;
                        visibility: hidden;
                        opacity: 0;
                        transition: all .2s ease-in-out;

                    }

                    input {
                        position: absolute;
                        left: 0;
                        width: 140px;
                        height: 40px;
                        opacity: 0;
                        cursor: pointer;
                        pointer-events: none;
                    }

                    .importOptionsInner {
                        width: 140px;
                        height: auto;
                        background: #fff;
                        border-radius: 4px;
                        padding: 4px;
                        position: relative;
                        right: 8px;
                        box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);

                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 40px;
                            padding: 0 6px;
                            border-bottom: solid 1px rgb(191 191 191 / 30%);

                            span {
                                font-family: var(--font-family-default);
                                color: var(--color-default-text);
                                font-weight: 400;
                                font-size: 12px;
                            }

                            em {
                                color: #000;
                                font-size: 20px;
                            }

                            &:hover {
                                border-radius: 4px;
                                background: #EFEFEF;
                            }

                            &:last-child {
                                border: none;
                            }

                            &.image {
                                input {
                                    position: absolute;
                                    left: 0;
                                    width: 140px;
                                    height: 40px;
                                    opacity: 0;
                                    cursor: pointer;
                                }
                            }
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            right: -6px;
                            top: 14px;
                            width: 0;
                            height: 0;
                            border-left: 7px solid #fff;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                        }
                    }

                    .exportOptionsBlock {
                        position: absolute;
                        right: -155px;
                        top: 44px;
                        visibility: hidden;
                        opacity: 0;
                        transition: all .2s ease-in-out;

                        .exportOptionsInner {
                            width: 140px;
                            height: auto;
                            background: #fff;
                            border-radius: 8px;
                            position: relative;
                            right: 8px;
                            border: 1px solid #ECEDF0;
                            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

                            div {
                                display: flex;
                                align-items: center;
                                height: 40px;
                                padding: 0 6px;

                                span {
                                    font-family: var(--font-family-default);
                                    color: var(--color-default-text);
                                    font-weight: 400;
                                    font-size: 12px;
                                }

                                em {
                                    color: #000;
                                    font-size: 20px;
                                }

                                &:hover {
                                    background: var(--Base-Purple-1, #F3EBFF);
                                }

                                &:last-child {
                                    border: none;
                                }
                            }

                        }
                    }

                    &.panel {
                        position: relative;

                        .boardSettingBlock {
                            position: absolute;
                            left: 135px;
                            top: 0;
                            visibility: hidden;
                            opacity: 0;
                            transition: all .2s ease-in-out;

                            .boardSettingBlockInner {
                                width: 225px;
                                height: 165px;
                                margin-left: 22px;
                                background: #fff;
                                box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
                                border-radius: 5px;
                                padding: 8px 12px;
                                position: relative;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: -6px;
                                    top: 16px;
                                    width: 0;
                                    height: 0;
                                    border-right: 7px solid #fff;
                                    border-top: 5px solid transparent;
                                    border-bottom: 5px solid transparent;
                                }
                            }
                        }

                        &:hover {
                            .boardSettingBlock {
                                left: 140px;
                                visibility: visible;
                                opacity: 1;
                                transition: all .2s ease-in-out;
                            }
                        }
                    }
                }
            }
        }

        &.active {
            .moreOptionsBlock {
                visibility: visible;
                opacity: 1;
                transition: all .2s ease-in-out;
            }
        }
    }

    .helpOption{
        width: max-content;
        height: 36px;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        margin-right: 12px;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;

        .helpWrapper {
            position: relative;

            &:hover {
                .helpInnerWrapper {
                    visibility: visible;
                    opacity: 1;
                    transition: all .2s ease-in-out;
                }
            }

            &:hover, &.active {
                .helpButtonMain {
                    transition-delay: 0ms;
                    background: var(--Base-Purple-1, #F3EBFF);
                }
            }
        }

        .helpInnerWrapper{
            position: absolute;
            right: 0;
            top: 39px;
            width: 180px;
            height: auto;
            background: #fff;
            border-radius: 8px;
            border: 1px solid  #ECEDF0;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
            visibility: hidden;
            opacity: 0;
            display: flex;
            flex-direction: column;
            transition: all .2s ease-in-out;

            .topTutorialButton,
            .topFeedbackButton,
            .topHelpButton
             {
                &__wrapper {
                    height: 40px;
                    transition: 100ms all ease-in-out;

                    &:hover {
                        background: var(--Base-Purple-1, #F3EBFF);
                    }
                }

                height: 100%;
                padding: 0 14px;
                display: flex;
                justify-content: start;
                align-items: center;

                img {
                    width: 15px;
                    height: 15px;
                }

                span{
                    font-family: var(--font-family-default);
                    color: var(--color-default-text);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.12px;
                    margin-left: 10px;
                }
            }

            .topHelpButton {
                img {
                    height: 8px;
                }
            }

            .topFeedbackButton, .topTutorialButton {
                em {
                    position: relative;
                    top: 1px;
                }
            }
        }

        .helpButtonWrapper {
            width: 36px;
            height: 36px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .helpButtonMain {
            background: #fff;
            border: 1px solid rgb(179 136 255 / 30%);
            transition-delay: 100ms;
        }

        .topHelpButton {
            padding: 8px;

            em {
                font-size: 16px;
                color: var(--color-default-text);
                position: relative;
                top: 2px;
                pointer-events: none;
            }
        }

        .topTutorialButton__wrapper {
            top: calc(200% + 10px);

        }
    }

    .commentBlock {
        width: max-content;
        height: 36px;
        overflow: hidden;
        box-sizing: border-box;
        background: #F5F5F5;
        border: 1px solid rgb(179 136 255 / 30%);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        position: relative;
        user-select: none;

        .commentBtn {
            position: relative;
            width: 36px;
            height: 36px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 8px;
            font-family: var(--font-family-default);
            color: #FFF;
            font-size: 18px;
            font-weight: 400;
            border: none;
            outline: none;
            padding-left: 10px;
            transition: width 0.2s ease;
            cursor: pointer;
            display: flex;
            justify-content: start;
            align-items: center;

            &:disabled {
                img {
                    opacity: 0.5;
                }

                cursor: not-allowed;
            }

            em {
                position: relative;
                top: 2px;
                font-size: 15px;
            }

            .comment-title{
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.1s ease-in;
                transition-delay: 0.2s;
                color: #262626;
                font-size: 14px;
                font-weight: 400;
                margin-left: 8px;
                margin-right: 8px;
            }

            &:not(:disabled):hover {
                width: 109px;
                background: var(--base-purple-1, #f3ebff);

                .comment-title{
                    visibility: visible;
                    opacity: 1;

                }
            }

            &.active {
                background: var(--base-purple-1, #f3ebff);
            }
        }
    }

    .layersBlock{
        width: max-content;
        height: 36px;
        overflow: hidden;
        box-sizing: border-box;
        background: #F5F5F5;
        border: 1px solid rgb(179 136 255 / 30%);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
        position: relative;

        &:hover .layersBtn, &.active .layersBtn{
            background: #F3EBFF;
        }

        .layersBtn {
            position: relative;
            width: 36px;
            height: 36px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 8px;
            font-family: var(--font-family-default);
            color: #FFF;
            font-size: 18px;
            font-weight: 400;
            border: none;
            outline: none;
            transition: width 0.2s ease;
            cursor: pointer;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }


    .shareBlock {
        width: max-content;
        height: 36px;
        background: #F5F5F5;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        position: relative;
        user-select: none;

        .shareBtn {
            position: relative;
            width: 36px;
            height: 36px;
            background: var(--Interactive-Bg-P1, #00E676);
            box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
            border-radius: 8px;
            font-family: var(--font-family-default);
            color: #262626;
            font-size: 18px;
            font-weight: 400;
            border: none;
            outline: none;
            padding-left: 10px;
            transition: width 0.2s ease;
            cursor: pointer;
            display: flex;
            justify-content: start;
            align-items: center;

            em {
                position: relative;
                top: 2px;
                font-size: 17px;
                color: var(--color-default-text);
            }

            .share-title{
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.1s ease-in;
                transition-delay: 0.2s;
                color: #262626;
                font-size: 14px;
                font-weight: 400;
                margin-left: 8px;
                margin-right: 8px;
            }

            .tooltiptext.coming_soon {
                bottom: -39px;
                width: 200px;
                left: -80px;

                &::after {
                    left: unset;
                    right: 0;
                }
            }

            &:not(:disabled):hover {
                width: 83px;

                .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                    transition: all .2s ease-in-out;
                }

                .share-title{
                    visibility: visible;
                    opacity: 1;

                }

                .topBar__right{
                    width: 677px;
                }
            }

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
                cursor: not-allowed;
                user-select: none;
            }
        }
    }
}

.delete-board-modal{
    .modal{
      &__content{
        width:470px;
        padding:24px;
      }
  
      &__title{
        font-size:20px;
        line-height:32px;
        margin-right:28px;
        overflow-wrap: anywhere;
  
        button{
          position:absolute;
          top:24px;
          right:18px;
        }
      }
  
      &__description{
        font-size:14px;
        line-height:20px;
  
        span{
          span{
            font-weight:500;
          }
        }
      }
  
      &__actions{
        justify-content:flex-end;
        margin: 32px -8px 0;
        
        button{
          height:36px;
          flex:none;
          font-size:14px;
          line-height:20px;
          padding:0;
  
          &:first-child{
            width:95px;
          }
  
          &:last-child{
            width:93px;
          }
        }
      }
    }
  
    #boardsDeleteBtn {
        background-color: #FF8A80;
    }
}
  
  .board-toast-container{
    #duplicate-success{
      width:470px;
      height:52px!important;
    }
  
    .wb_toast__duplicate-board {
        border: 1px solid var(--Brand-Purple-4, #651fff);
        padding: 8px 11px 8px 16px !important;
    }
  
    .duplicate-board_toast{
      &__text{
        font-size:14px;
        line-height:20px;
        font-family:Rubik, sans-serif;
        display:flex;
        align-items:center;
      }
  
      &__button{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width:166px;
        background: var(--Interactive-Bg-P3, #7c4dff);
        color: #fff;
        margin-left: 8px;
        padding-inline: 16px;
        border-radius: 8px;
        cursor: pointer;
        
        &.btn-width{
            width: 134px;
        }
      }
  
      &__loader{
        width:17px;
        height:17px;
        margin-right:8px;
  
        svg{
          vertical-align:top;
        }
  
        +span{
          margin-right:8px;
        }
      }
    }
  
    .Toastify__close-button{
      margin-left:5px!important;
    }
  
    #duplicate-loading{
      .Toastify__close-button{
        svg{
          width:18.5px;
          height:18.5px;
        }
      }
    }
  
    .wb_toast__delete-board {
      button{
        margin-left:20px!important;
      }
    }
}

.layers-button-tooltip {
    top: 64px !important;
    background: black;

    svg {
        path {
            fill: black;
        }
    }
}


