@import "../../utils";

$rightPanelBorderColor: #DFE0E6;
$rightPanelBgColor: #f7f7f7;

.commentDrawer {
    transition: 200ms all ease-in-out;
    will-change: transform;
    transform: translateX(283px);
    visibility: hidden;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    z-index: 99;
    width: 283px;
    display:flex; 
    flex-direction: column;

    .searchInput{
        &__markActions{
            display:none;
            gap:8px;
            align-items:center;
            position:absolute;
            top: 66%;
            transform: translateY(-50%);
            right:40px;

            &--count{
                color:#C0C3CE;
                font-size:12px;
                line-height:16px;
                letter-spacing:1%;
            }

            button:disabled{
                cursor:not-allowed;
            }
        }

        &__clearBtn{
            display:none;
            top:66%;
            right:22px;
        }
    }

    &__content{
        &--error {
            display: grid;
            place-items: center;
            height: 100%;

            .errorCard {
                display: grid;
                grid-template-columns: 50px 1fr;
                gap: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .error__message {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
            }
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__commentList {
        padding:0 8px;
        margin-top: 10px;
        overflow-y: auto;
        flex-grow: 1;
        flex-basis: 0;
        position:relative;
        scroll-margin: 4px;
        scrollbar-color: var(--base-blue-grey-6, #afb2c0) var(--base-blue-grey-2, #ecedf0);
        scrollbar-gutter: auto;
        scrollbar-width: thin;

        .commentItem {
            width: 100%;

            mark {
                background: #d9c4ff40;
                border-radius:2px;
                position:relative;
                padding:1px;
                color:#0000009d;
                z-index:0;

                &.current{
                    border:1px solid var(--Base-Purple-5, #6200EA);
                }
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        height: 57px;
        padding: 0 16px;
        box-sizing: border-box;
        border-bottom: 1px solid $rightPanelBorderColor;
        background-color: #fff;
        box-shadow: 0 10px 80px rgb(0 0 0 / 5%);

        span {
            font-size: 12px;
            font-weight: 500;
        }

        img{
            height:12.67px;
            width:12.67px;
        }
    }

    &__closeBtn {
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        width: 14px;
        height: 14px;
    }

    &.__visible {
        transform: translateX(0);
        visibility: visible;

        .searchInput__markActions, .searchInput__clearBtn{
            display:flex;
        }

        .commentDrawer__content{
            height: 100%;
        }

        .commentDrawer__footer {
            display:flex!important;
        }
    }

    button {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
    }

    .searchInput--wrapper{
        width:100%;
        padding:20px 14px 0;

        .searchInput__btn{
            top:68%;
            left:30px;
        }

        .searchInput__input{
            padding: 10px 16px 10px 45px;
            background:transparent;
            font-size:14px;
            border-bottom:1px solid #DFE0E6;
            border-radius:0;

            &::placeholder{
                color:#676B7E;
            }

            &:focus-visible{
                outline:none;
                border-bottom: 1px solid var(--Base-Purple-5, #651FFF);
            }
        }
    }

    &__noResult{
        text-align:center;
        margin-top:40px;

        p{
            font-size:14px;
            font-weight:500;
        }
    }

    &__actions{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-top:20px;
        padding:0 8px;

        &--markBtn,
        &--filterBtn{
            display:flex;
            align-items:center;
            gap:8px;
            color:var(--Base-Purple-5, #6200EA);
            font-size:14px;
            font-weight:500;
            padding:3px!important;
            border-radius:2px 5px;
            border-bottom-left-radius: 5px;
        }

        &--filterBtn.active{
            background-color:#F3EBFF;
        }

        &--markBtn{
            &.disabled{
                color:#C0C3CE;
                pointer-events:none;
                cursor:not-allowed!important;
            }
        }

        &--filterMenu{
            /* layout */
            display: flex;
            width: 140px;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            z-index: 100;

            /* style */
            border-radius: 8px;
            border: 1px solid var(--base-blue-grey-2, #ECEDF0);
            background: var(--0, #FFF);

            /* Main Shadows/E100 */
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);


            &Item {
                all: unset;

                /* layout */
                box-sizing:border-box;
                height:40px;
                display: flex;
                justify-content:space-between;
                padding: 12px 16px;
                align-items: center;
                gap: 8px;
                align-self: stretch;

                /* style */
                background: var(--0, #FFF);
                cursor: pointer;

                /* typography */
                color: var(--blue-grey-10, var(--base-blue-grey-10, #3C3E49));

                /* Body/XS/Regular */
                font-family: var(--font-family-default);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;

                /* 133.333% */
                line-height: 16px;
                letter-spacing: 0.12px;


                &.disabled {
                    opacity: .25;
                    pointer-events: none;
                }

                &:first-child {
                    border-radius: 4px 4px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 4px 4px;
                }

                &:hover {
                    background: var(--blue-gray-2, #ECEDF0);
                }
            }
        }
    }

    &__footer {
        margin-top: 10px;
        display: none;
        flex-direction: column;
        justify-content: flex-end;
        border-top: 1px solid #DFE0E6;
        padding:20px 12px;
    }

    &__checkboxes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__checkboxGroup:last-child {
        margin-left: 38px;
    }
}